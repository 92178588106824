import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import IconStartOver from '../../assets/svg/startover.svg';
import { StrateMode } from '../../constants/strates';
import { TemplateTypes } from '../../constants/templateTypes';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import {
  formatLiveGridPlayerData,
  formatLiveTrackingObject,
} from '../../helpers/liveTV/liveTV-helper';
import { useTranslation } from '../../lang';
import { launchPlayerFullScreen } from '../../store/slices/player-thunk';
import { isStartOverAuthorizedByEpgIDSelector } from '../../store/slices/user-selectors';
import Button from '../Button/Button';
import styles from './StartOverButton.css';

export type StartOverButtonProps = {
  channel: ApiV2BroadcastChannel;
  context?: ApiV2Context;
  customHandler?: () => void;
  title?: string;
};

function StartOverButton({
  channel,
  context,
  customHandler,
  title = '',
}: StartOverButtonProps): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isStartOverAuthorizedByEpgID = useSelector(
    isStartOverAuthorizedByEpgIDSelector
  );

  const isStartOverAuthorized = Boolean(
    channel.epgID &&
      isStartOverAuthorizedByEpgID[
        typeof channel.epgID === 'string'
          ? parseInt(channel.epgID, 10)
          : channel.epgID
      ]
  );

  if ($_BUILD_RENDERMODE_CSR || !isStartOverAuthorized) {
    return null;
  }

  const startOverTitle = `${t('StartOver.seeFromBeginning')} ${title}`;

  const buttonHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (customHandler) {
      customHandler();
    } else {
      event.persist();
      const tracking = formatLiveTrackingObject({ channel, type: 'Startover' });
      const buttonData =
        formatLiveGridPlayerData(
          channel.epgID,
          { ...context, ...tracking },
          true
        ) || {};

      dispatch(
        launchPlayerFullScreen({
          event,
          data: buttonData,
          type: StrateMode.LiveTv,
        })
      );
    }
  };

  return (
    <Button
      className={styles.startOverButton__button}
      color={TemplateTypes.DEFAULT}
      handler={buttonHandler}
      icon={<IconStartOver className={styles.startOverButton__icon} />}
      ariaLabel={startOverTitle}
      title={startOverTitle}
    />
  );
}

export default StartOverButton;
